/* DateInput.css */
.date-input {
    background-color: #ffffff; /* theme.colors.surface */
    height: 40px;
    padding: 10px;
    border-width: 1px;
    border-color: #cccccc; /* theme.colors.secondary */
    border-radius: 5px;
    /* Add more styles for better visibility and interactivity */
  }
  
  .date-input.disabled {
    background-color: rgba(128, 128, 128, 0.5); /* theme.colors.disabled */
    color: #aaaaaa; /* theme.colors.disabledText */
  }
  
  .date-input.error {
    color: #ff0000; /* theme.colors.error */
    border-color: #ff0000; /* theme.colors.error */
  }
  
  .description {
    font-size: 13px;
    color: #cccccc; /* theme.colors.secondary */
    margin-top: 8px;
  }
  
  .error-text {
    color: #ff0000; /* theme.colors.error */
  }

  .date-input.has-error::placeholder {
    color: #ff0000; /* theme.colors.error */
    opacity: 1; /* For Firefox */
  }
  
  /* For Internet Explorer 10-11 */
  .date-input.has-error:-ms-input-placeholder {
    color: #ff0000; /* theme.colors.error */
  }
  
  /* For Microsoft Edge */
  .date-input.has-error::-ms-input-placeholder {
    color: #ff0000; /* theme.colors.error */
  }